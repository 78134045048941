
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { KML } from 'ol/format';

const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      source: new XYZ({
        url: 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png'
      })
    })
  ],
  view: new View({
    center: [0, 0],
    zoom: 2,
    zoomDuration: 2400,
  })
});


  // Load the KML file and add it to the map
  const kmlURL = './map.kml';
  const kmlSource = new VectorSource({
      url: kmlURL,
      format: new KML()
  });

  const kmlLayer = new VectorLayer({
      source: kmlSource
  });

  map.addLayer(kmlLayer);